.licensePlate {
    text-align: center;
    width: 100%;
    font-size: 25px;
    padding: 15px;
    border: 3px solid #e1e1e1;
    border-radius: 10px;
    background-color: #e1e1e1;
    box-sizing: border-box;
}

.licensePlate:invalid {
    border-color: red;
    outline: none;
}

.subtitle {
    text-align: center;
    display: block;
    margin: 10px 0 0;
    color: #0075c0;
    font-size: 14px;
}

.info {
    text-align: center;
    font-size: 12px;
    margin: 5px 0 40px 0;
}
