.container {
    position: relative;
    text-align: center;
}

.title {
    font-size: 24px;
    font-weight: bold;
    width: 80%;
    margin: 30px auto;
}

.stationInfo {
    display: inline-block;
    text-align: left;
    margin: 0 auto;
    white-space: nowrap;
}

.logo {
    position: absolute;
    width: 32px;
    height: 32px;
}

.address {
    padding-left: 42px;
}

.animationContainer {
    margin-top: 40px;
    width: 100%;
    height: 350px;
    overflow: hidden;
    position: relative;
}

.animation {
    position: absolute;
    top: -300px;
    bottom: -150px;
    width: 100%;
}

.info {
    font-size: 16px;
    text-align: center;
}
