.banner {
    width: 100%;
    display: block;
}

.title {
    margin-top: 20px;
    text-align: center;
    font-size: 16px;
}

.payment {
    padding: 0 30px;
    max-width: 500px;
    margin: 0 auto;
}

.agb {
    font-size: 12px;
    text-align: center;
}
