@font-face {
    font-family: "WorkSans";
    src: url("./assets/fonts/WorkSans/WorkSans-Light.ttf");
    font-weight: 100; }

@font-face {
    font-family: "WorkSans";
    src: url("./assets/fonts/WorkSans/WorkSans-LightItalic.ttf");
    font-weight: 100;
    font-style: italic; }

@font-face {
    font-family: "WorkSans";
    src: url("./assets/fonts/WorkSans/WorkSans-Regular.ttf");
    font-weight: normal; }

@font-face {
    font-family: "WorkSans";
    src: url("./assets/fonts/WorkSans/WorkSans-MediumItalic.ttf");
    font-weight: normal;
    font-style: italic; }

@font-face {
    font-family: "WorkSans";
    src: url("./assets/fonts/WorkSans/WorkSans-Bold.ttf");
    font-weight: bold; }

@font-face {
    font-family: "WorkSans";
    src: url("./assets/fonts/WorkSans/WorkSans-BoldItalic.ttf");
    font-weight: bold;
    font-style: italic; }

@font-face {
    font-family: "WorkSans";
    src: url("./assets/fonts/WorkSans/WorkSans-ExtraBold.ttf");
    font-weight: 900; }

@font-face {
    font-family: "WorkSans";
    src: url("./assets/fonts/WorkSans/WorkSans-ExtraBoldItalic.ttf");
    font-weight: 900;
    font-style: italic; }

body {
    font-family: "WorkSans", serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    margin: 0 auto;
    max-width: 500px;
}
