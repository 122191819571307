.container {
    position: relative;
}

.washCodeContainer {
    background-color: #0076bf;
    color: #fff;
    padding: 15px;
    text-align: center;
    margin-bottom: 20px;
}

.washCodeTitle {
    font-weight: normal;
    margin: 0;
}

.washCode {
    letter-spacing: 10px;
    margin: 10px 0 0;
    font-weight: bold;
}

.title {
    font-size: 24px;
    text-align: center;
    font-weight: bold;
    width: 80%;
    position: absolute;
    margin: 0 auto;
    left: 0;
    right: 0;
    z-index: 99;
}

.animationContainer {
    margin-top: 120px;
    width: 100%;
    height: 350px;
    overflow: hidden;
    position: relative;
}

.animation {
    position: absolute;
    top: -300px;
    bottom: -150px;
    width: 100%;
}

.rewindButton {
    position: absolute;
    top: 190px;
    left: 0;
    right: 0;
    margin: 0 auto;
    background: url("../../../assets/images/rewind.svg") no-repeat left top;
    width: 60px;
    height: 30px;
    border: 0;
    z-index: 99;
    cursor: pointer;
}
