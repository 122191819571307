.conditions {
    margin: 35px 0 30px 0;
    padding: 0 30px 0 10px;
    text-indent: 0;
    list-style-type: none;
}

.conditions li {
    font-size: 14px;
    margin: 0 0 20px;
    padding-left: 60px;
    background: url("../../../assets/images/lightbulb.svg") no-repeat left 3px;
    background-size: 35px;
}

.conditions li:last-child {
    margin-bottom: 0;
}
