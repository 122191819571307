.washCodeWrapper {
    background-color: #30aef6;
    padding: 30px 0 50px 0;
    color: #fff;
}

.title {
    text-align: center;
    font-size: 25px;
    max-width: 250px;
    margin: 0 auto;
}

.washCodeTitle {
    margin-top: 50px;
    text-align: center;
    font-size: 20px;
}

.washCode {
    text-align: center;
    color: #30aef6;
    font-size: 35px;
    font-weight: bold;
}

.washCodeDigit {
    border-radius: 10px;
    background-color: #fff;
    margin: 3px;
    line-height: 50px;
    width: 32px;
    display: inline-block;
}

.expirationDate {
    text-align: center;
    font-size: 20px;
}

.notes {
    margin: 60px auto;
    padding-left: 60px;
    background: url("../../assets/images/lightbulb.svg") no-repeat left 3px;
    background-size: 35px;
    max-width: 300px;
}

.hilfeButton {
    display: none;
    margin: 0 auto;
    font-size: 16px;
    text-align: center;
    color: #000;
    cursor: pointer;
    max-width: 100px;
    background: none;
    border: 0;
    text-decoration: underline;
}
