.button {
    display: block;
    text-align: center;
    margin: 30px auto;
    width: 80%;
    border: 3px solid #000;
    border-radius: 20px;
    height: 40px;
    color: #000;
    font-size: 20px;
    font-weight: bold;
    text-decoration: none;
    background-color: transparent;
    padding: 0;
    box-sizing: content-box;
    cursor: pointer;
}
